<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="loading_box" v-if="loading == true">
        <img src="../../../../assets/img/black_small.gif" alt="" />
        <p>正在努力加载试卷，请耐心等待~</p>
      </div>
      <div class="content" v-else>
        <!-- 试卷标题 -->
        <div class="title_box" id="title">{{ title }}</div>
        <!-- <div id="scroll_box"></div> -->
        <!-- 试题 -->
        <div class="test_questions_box">
          <!-- 左边试题 -->
          <!-- 1 单选   2 配伍题   3 材料分析选择题(案例题)   4 多选   5 材料分析题(主观题) -->
          <!--qu_metatype: 1 纯文字   2 富文本  3 图片链接 -->
          <div class="left_topic_box">
            <!-- 单选题 -->
            <div class="topic_box" v-if="radio === 1">
              <div class="rule_box" id="radio">单选题</div>
              <div
                v-for="(item, index) in paper_arr.filter(
                  (res) => res.qtype == 1
                )"
                :key="index + 'd'"
                class="topic_el"
              >
                <div style="height: 0px" :id="item.num"></div>
                <!-- 题目 -->
                <div class="topic_title_box">
                  <!-- 纯文字展示 -->
                  <div class="topic" v-show="item.qu_metatype == 1">
                    <em>{{ item.num }}.</em><i>[单选题]</i>{{ item.qu_content }}
                  </div>
                  <!-- 富文本展示 -->
                  <div class="topic" v-show="item.qu_metatype == 2">
                    <em>{{ item.num }}.</em><i>[单选题]</i>
                    <p v-html="item.qu_content"></p>
                  </div>
                  <!-- 图片展示 -->
                  <div class="topic topic_img" v-show="item.qu_metatype == 3">
                    <div>
                      <em>{{ item.num }}.</em><i>[单选题]</i>
                    </div>
                    <img :src="item.qu_content" alt="" />
                  </div>
                </div>
                <!-- 选项 -->
                <div class="option_box">
                  <div
                    class="option_el"
                    v-for="(el, index) in item.option"
                    :key="index + 'dx'"
                    v-show="el.option_content != null"
                  >
                    <!-- 纯文字 -->
                    <div class="option hover" v-show="el.option_type == 1">
                      <button
                        :class="[
                          item.answer == el.select ? 'yes' : '',
                          item.answer != item.user_answer &&
                          item.user_answer == el.select
                            ? 'wrong'
                            : '',
                        ]"
                        type="button"
                      >
                        {{ el.select }}
                      </button>
                      <div>{{ el.option_content }}</div>
                    </div>
                    <!-- 富文本 -->
                    <div
                      class="option option_rich_text hover"
                      v-show="el.option_type == 2"
                    >
                      <button
                        type="button"
                        :class="[
                          item.answer == el.select ? 'yes' : '',
                          item.answer != item.user_answer &&
                          item.user_answer == el.select
                            ? 'wrong'
                            : '',
                        ]"
                      >
                        {{ el.select }}
                      </button>
                      <div v-html="el.option_content"></div>
                    </div>
                    <!-- 纯图片 -->
                    <div class="option option_img" v-show="el.option_type == 3">
                      <div class="option_left">
                        <button
                          type="button"
                          :class="[
                            item.answer == el.select ? 'yes' : '',
                            item.answer != item.user_answer &&
                            item.user_answer == el.select
                              ? 'wrong'
                              : '',
                          ]"
                        >
                          {{ el.select }}
                        </button>
                        <img :src="el.option_content" alt="" />
                      </div>
                      <a
                        :href="el.option_content"
                        class="option_right"
                        @click="yulan(el)"
                        target="_blank"
                      >
                        <img src="../../../../assets/img/yulan.png" alt="" />
                        大图预览
                      </a>
                    </div>
                  </div>
                </div>
                <!-- 答案解析 -->
                <div class="analysis_card_box" ref="analysis_card">
                  <div class="analysis_card_top">
                    <div>正确答案:&nbsp;{{ item.answer }}</div>
                    <div
                      class="your_answer"
                      v-if="item.answer == item.user_answer"
                    >
                      您的答案:&nbsp;{{ item.user_answer }}
                      <img src="../../../../assets/img/dui_answer.png" alt="" />
                    </div>
                    <div
                      class="your_answer wrong_answer"
                      v-else-if="
                        item.answer != item.user_answer &&
                        item.user_answer != ''
                      "
                    >
                      您的答案:&nbsp;{{ item.user_answer }}
                      <img src="../../../../assets/img/wrong.png" alt="" />
                    </div>
                    <div class="your_answer wrong_answer" v-else>
                      您的答案:&nbsp;未作答
                    </div>
                  </div>
                  <div class="analysis_card_down">
                    <i>答案解析:</i>
                    <div v-if="item.an_detail_metatype == 1">
                      {{ item.an_detail_content }}
                    </div>
                    <div
                      v-else-if="item.an_detail_metatype == 2"
                      v-html="item.an_detail_content"
                    ></div>
                    <img v-else :src="item.an_detail_content" alt="" />
                  </div>
                </div>

                <!-- 收藏 -->
                <div class="coolect_box">
                  <div
                    class="coolect_left"
                    @click="isCollect(item)"
                    v-if="item.collect_status == false"
                  >
                    <img src="../../../../assets/img/collect.png" alt="" />
                    收藏试题
                  </div>
                  <div
                    class="coolect_left no_collect"
                    @click="noCollect(item)"
                    v-if="item.collect_status == true"
                  >
                    <img src="../../../../assets/img/no_collect.png" alt="" />
                    取消收藏
                  </div>
                  <div class="collect_line"></div>
                  <div class="coolect_right" @click="errorCorrection(item)">
                    <img src="../../../../assets/img/jiucuo.png" alt="" />
                    试题纠错
                  </div>
                </div>
              </div>
            </div>
            <!-- 配伍题 -->
            <div class="topic_box peiwu_topic_box" v-if="peiwu === 2">
              <div class="rule_box" id="peiwu">
                配伍题&nbsp;&nbsp;&nbsp;（下列每小题中，只有一项是最符合题意的正确答案，多选、错选或不选均不得分。每小题为1分。)
              </div>
              <div
                v-for="(item, index) in paper_arr.filter(
                  (res) => res.qtype == 2
                )"
                :key="index + 'd'"
                class="topic_el"
              >
                <!-- {{item.arr.id}} -->
                [配伍题]根据下列选项,回答以下问题:
                <!-- 题目 -->
                <div class="peiwu_title_box">
                  <div
                    class="title_el"
                    v-for="(el, index) in item.option"
                    :key="index + 'title'"
                    v-show="el.option_content != null"
                  >
                    <!-- 纯文字 -->
                    <div v-show="el.option_type == 1">
                      <i>{{ el.select }}.</i>&nbsp;&nbsp;{{ el.option_content }}
                    </div>
                    <!-- 富文本 -->
                    <div v-show="el.option_type == 2">
                      <i>{{ el.select }}.</i>&nbsp;&nbsp;
                      <p v-html="el.option_content"></p>
                    </div>
                    <!-- 纯图片 -->
                    <div v-show="el.option_type == 3" class="option_img">
                      <div class="option_left">
                        <i>{{ el.select }}.</i>&nbsp;&nbsp;
                        <img :src="el.option_content" alt="" />
                      </div>
                      <a
                        :href="el.option_content"
                        class="option_right"
                        target="_blank"
                      >
                        <img
                          src="../../../../assets/img/yulan.png"
                          alt=""
                        />大图预览
                      </a>
                    </div>
                  </div>
                </div>
                <!-- 下方切换 -->
                <div class="toogle_box">
                  <ul class="toogle_top">
                    <li
                      v-for="(res, index) in item.qu_list"
                      :key="index + 'res'"
                      @click="changeCard(item, index)"
                      :class="{ card_style: item.card_index == index }"
                      :id="res.num"
                    >
                      {{ res.num }}
                    </li>
                  </ul>
                  <div class="toogle_down">
                    <!-- <li>{{item.qu_list[item.card_index].qu_content}}</li> -->
                    <div
                      class="toogle_down_el"
                      v-for="(res, index) in item.qu_list"
                      :key="index + 'res'"
                      v-show="index == item.card_index"
                    >
                      <!-- 题目 qu_metatype: 1纯文字  2富文本 3纯图片-->
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 1"
                      >
                        {{ res.num }}.&nbsp;{{ res.qu_content }}
                      </div>
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 2"
                      >
                        <em>{{ res.num }}.</em>
                        <div v-html="res.qu_content"></div>
                      </div>
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 3"
                      >
                        <em>{{ res.num }}.</em>
                        <img :src="res.qu_content" alt="" />
                      </div>
                      <!-- 选项 -->
                      <div class="option_box">
                        <div
                          class="option_el"
                          v-for="(el, index) in res.option"
                          :key="index + 'dx'"
                          v-show="el.option_content != null"
                        >
                          <!-- 纯文字 -->
                          <div
                            class="option hover"
                            v-show="el.option_type == 1"
                          >
                            <button
                              :class="[
                                res.answer == el.select ? 'yes' : '',
                                res.answer != res.user_answer &&
                                res.user_answer == el.select
                                  ? 'wrong'
                                  : '',
                              ]"
                              type="button"
                            >
                              {{ el.select }}
                            </button>
                            <div>{{ el.option_content }}</div>
                          </div>
                          <!-- 富文本 -->
                          <div
                            class="option option_rich_text hover"
                            v-show="el.option_type == 2"
                          >
                            <button
                              type="button"
                              :class="{
                                blue: el.select == el.choose,
                              }"
                            >
                              {{ el.select }}
                            </button>
                            <div v-html="el.option_content"></div>
                          </div>
                          <!-- 纯图片 -->
                          <div
                            class="option option_img"
                            v-show="el.option_type == 3"
                          >
                            <div class="option_left">
                              <button
                                type="button"
                                :class="{
                                  blue: el.select == el.choose,
                                }"
                              >
                                {{ el.select }}
                              </button>
                              <img :src="el.option_content" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 答案解析 -->
                  <div
                    class="analysis_card_box"
                    ref="analysis_card"
                    v-for="(res, index) in item.qu_list"
                    :key="index + 'res'"
                    v-show="index == item.card_index"
                  >
                    <div class="analysis_card_top">
                      <div>正确答案:&nbsp;{{ res.answer }}</div>
                      <div
                        class="your_answer"
                        v-if="item.answer == res.user_answer"
                      >
                        您的答案:&nbsp;{{ res.user_answer }}
                        <img
                          src="../../../../assets/img/dui_answer.png"
                          alt=""
                        />
                      </div>
                      <div
                        class="your_answer wrong_answer"
                        v-else-if="
                          res.answer != res.user_answer && res.user_answer != ''
                        "
                      >
                        您的答案:&nbsp;{{ res.user_answer }}
                        <img src="../../../../assets/img/wrong.png" alt="" />
                      </div>
                      <div class="your_answer wrong_answer" v-else>
                        您的答案:&nbsp;未作答
                      </div>
                    </div>
                    <div class="analysis_card_down">
                      <i>答案解析:</i>
                      <div v-if="item.an_detail_metatype == 1">
                        {{ item.an_detail_content }}
                      </div>
                      <div
                        v-else-if="item.an_detail_metatype == 2"
                        v-html="item.an_detail_content"
                      ></div>
                      <img v-else :src="item.an_detail_content" alt="" />
                    </div>
                  </div>
                </div>
                <!-- 收藏 -->
                <div class="coolect_box">
                  <div
                    class="coolect_left"
                    @click="isCollect(item)"
                    v-if="item.collect_status == false"
                  >
                    <img src="../../../../assets/img/collect.png" alt="" />
                    收藏试题
                  </div>
                  <div
                    class="coolect_left no_collect"
                    @click="noCollect(item)"
                    v-if="item.collect_status == true"
                  >
                    <img src="../../../../assets/img/no_collect.png" alt="" />
                    取消收藏
                  </div>
                  <div class="collect_line"></div>
                  <div class="coolect_right" @click="errorCorrection(item)">
                    <img src="../../../../assets/img/jiucuo.png" alt="" />
                    试题纠错
                  </div>
                </div>
                <!-- {{qu_list}} -->
                <!-- 选项 -->
              </div>
            </div>
            <!-- 材料分析选择题 -->
            <div class="topic_box peiwu_topic_box" v-if="stuff_case === 3">
              <div class="rule_box" id="stuff_case">材料分析选择题</div>
              <div
                v-for="(item, index) in paper_arr.filter(
                  (res) => res.qtype == 3
                )"
                :key="index + 'd'"
                class="topic_el"
              >
                <!-- 题目 -->
                <div class="topic_title_box">
                  <!-- 纯文字展示 -->
                  <div class="topic" v-show="item.case_metatype == 1">
                    <i>[材料分析选择题]</i>{{ item.case_content }}
                  </div>
                  <!-- 富文本展示 -->
                  <div class="topic" v-show="item.case_metatype == 2">
                    <i>[材料分析选择题]</i>
                    <p v-html="item.case_content"></p>
                  </div>
                  <!-- 图片展示 -->
                  <div class="topic topic_img" v-show="item.case_metatype == 3">
                    <div>
                      <i>[材料分析选择题]</i>
                    </div>
                    <img :src="item.case_content" alt="" />
                  </div>
                </div>
                <!-- 下方切换 -->
                <div class="toogle_box">
                  <ul class="toogle_top">
                    <li
                      v-for="(res, index) in item.qu_list"
                      :key="index + 'res'"
                      @click="changeCard(item, index)"
                      :class="{ card_style: item.card_index == index }"
                      :id="res.num"
                    >
                      {{ res.num }}
                    </li>
                  </ul>
                  <div class="toogle_down">
                    <!-- <li>{{item.qu_list[item.card_index].qu_content}}</li> -->
                    <div
                      class="toogle_down_el"
                      v-for="(res, index) in item.qu_list"
                      :key="index + 'res'"
                      v-show="index == item.card_index"
                    >
                      <!-- 题目 qu_metatype: 1纯文字  2富文本 3纯图片-->
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 1"
                      >
                        {{ res.num }}.[{{
                          `${
                            [1, undefined, ""].includes(res.option_type)
                              ? "单选题"
                              : "多选题"
                          }`
                        }}]&nbsp;{{ res.qu_content }}
                      </div>
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 2"
                      >
                        <em>{{ res.num }}.</em>
                        <div v-html="res.qu_content"></div>
                      </div>
                      <div
                        class="small_topic_title"
                        v-show="res.qu_metatype == 3"
                      >
                        <em>{{ res.num }}.</em>
                        <img :src="res.qu_content" alt="" />
                      </div>
                      <!-- 选项 -->
                      <div class="option_box">
                        <div
                          class="option_el"
                          v-for="(el, index) in res.option"
                          :key="index + 'dx'"
                          v-show="el.option_content != null"
                        >
                          <!-- 纯文字 -->
                          <div
                            class="option hover"
                            v-show="el.option_type == 1"
                          >
                            <button
                              :class="[
                                res.answer == el.select ||
                                res.answer.includes(el.select)
                                  ? 'yes'
                                  : '',
                                (res.answer != res.user_answer &&
                                  res.user_answer == el.select) ||
                                res.wrong_option == el.select
                                  ? 'wrong'
                                  : '',
                              ]"
                              type="button"
                            >
                              {{ el.select }}
                            </button>
                            <div>{{ el.option_content }}</div>
                          </div>
                          <!-- 富文本 -->
                          <div
                            class="option option_rich_text hover"
                            v-show="el.option_type == 2"
                          >
                            <button
                              type="button"
                              :class="[
                                res.answer == el.select ||
                                res.answer.includes(el.select)
                                  ? 'yes'
                                  : '',
                                (res.answer != res.user_answer &&
                                  res.user_answer == el.select) ||
                                res.wrong_option == el.select
                                  ? 'wrong'
                                  : '',
                              ]"
                            >
                              {{ el.select }}
                            </button>
                            <div v-html="el.option_content"></div>
                          </div>
                          <!-- 纯图片 -->
                          <div
                            class="option option_img"
                            v-show="el.option_type == 3"
                          >
                            <div class="option_left">
                              <button
                                type="button"
                                :class="[
                                  res.answer == el.select ||
                                  res.answer.includes(el.select)
                                    ? 'yes'
                                    : '',
                                  (res.answer != res.user_answer &&
                                    res.user_answer == el.select) ||
                                  res.wrong_option == el.select
                                    ? 'wrong'
                                    : '',
                                ]"
                              >
                                {{ el.select }}
                              </button>
                              <img :src="el.option_content" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 答案解析 -->
                  <div
                    class="analysis_card_box"
                    ref="analysis_card"
                    v-for="(res, index) in item.qu_list"
                    :key="index + 'res'"
                    v-show="index == item.card_index"
                  >
                    <div class="analysis_card_top">
                      <div>正确答案:&nbsp;{{ res.answer }}</div>
                      <div
                        class="your_answer"
                        v-if="res.answer == res.user_answer"
                      >
                        您的答案:&nbsp;{{ res.user_answer }}
                        <img
                          src="../../../../assets/img/dui_answer.png"
                          alt=""
                        />
                      </div>
                      <div
                        class="your_answer wrong_answer"
                        v-else-if="
                          res.answer != res.user_answer && res.user_answer != ''
                        "
                      >
                        您的答案:&nbsp;{{ res.user_answer }}
                        <img src="../../../../assets/img/wrong.png" alt="" />
                      </div>
                      <div class="your_answer wrong_answer" v-else>
                        您的答案:&nbsp;未作答
                      </div>
                    </div>
                    <div class="analysis_card_down">
                      <i>答案解析:</i>
                      <div v-if="res.an_detail_metatype == 1">
                        {{ res.an_detail_content }}
                      </div>
                      <div
                        v-else-if="res.an_detail_metatype == 2"
                        v-html="res.an_detail_content"
                      ></div>
                      <img v-else :src="res.an_detail_content" alt="" />
                    </div>
                  </div>
                </div>
                <!-- 收藏 -->
                <div class="coolect_box">
                  <div
                    class="coolect_left"
                    @click="isCollect(item)"
                    v-if="item.collect_status == false"
                  >
                    <img src="../../../../assets/img/collect.png" alt="" />
                    收藏试题
                  </div>
                  <div
                    class="coolect_left no_collect"
                    @click="noCollect(item)"
                    v-if="item.collect_status == true"
                  >
                    <img src="../../../../assets/img/no_collect.png" alt="" />
                    取消收藏
                  </div>
                  <div class="collect_line"></div>
                  <div class="coolect_right" @click="errorCorrection(item)">
                    <img src="../../../../assets/img/jiucuo.png" alt="" />
                    试题纠错
                  </div>
                </div>
                <!-- {{qu_list}} -->
                <!-- 选项 -->
              </div>
            </div>
            <!-- 多选题 -->
            <div class="topic_box" v-if="checkbox === 4">
              <div class="rule_box" id="checkbox">多选题</div>
              <div
                v-for="(item, index) in paper_arr.filter(
                  (res) => res.qtype == 4
                )"
                :key="index + 'd'"
                class="topic_el"
              >
                <div>
                  <div style="height: 0px" :id="item.num"></div>
                  <!-- <a :href="item.num"></a> -->
                  <!-- 题目 -->
                  <div class="topic_title_box">
                    <!-- 纯文字展示 -->
                    <div class="topic" v-show="item.qu_metatype == 1">
                      <em>{{ item.num }}.</em><i>[多选题]</i
                      >{{ item.qu_content }}
                    </div>
                    <!-- 富文本展示 -->
                    <div class="topic" v-show="item.qu_metatype == 2">
                      <em>{{ item.num }}.</em><i>[多选题]</i>
                      <p v-html="item.qu_content"></p>
                    </div>
                    <!-- 图片展示 -->
                    <div class="topic topic_img" v-show="item.qu_metatype == 3">
                      <div>
                        <em>{{ item.num }}.</em><i>[多选题]</i>
                      </div>
                      <img :src="item.qu_content" alt="" />
                    </div>
                  </div>
                  <!-- 选项 -->
                  <div class="option_box">
                    <div
                      class="option_el"
                      v-for="(el, index) in item.option"
                      :key="index + 'dx'"
                      v-show="el.option_content != null"
                      @click="chooseAnswer(item, el)"
                    >
                      <!-- 纯文字 -->
                      <div class="option hover" v-show="el.option_type == 1">
                        <!-- yes
                        wrong -->
                        <button
                          type="button"
                          :class="[
                            item.answer.includes(el.select) ? 'yes' : '',
                            item.wrong_option == el.select ? 'wrong' : '',
                          ]"
                        >
                          <!-- item.user_answer.includes(el.select) ? 'wrong' : '', -->
                          {{ el.select }}
                        </button>
                        <div>{{ el.option_content }}</div>
                      </div>
                      <!-- 富文本 -->
                      <div
                        class="option option_rich_text hover"
                        v-show="el.option_type == 2"
                      >
                        <button
                          type="button"
                          :class="[
                            item.answer.includes(el.select) ? 'yes' : '',
                            item.wrong_option == el.select ? 'wrong' : '',
                          ]"
                        >
                          {{ el.select }}
                        </button>
                        <div v-html="el.option_content"></div>
                      </div>
                      <!-- 纯图片 -->
                      <div
                        class="option option_img"
                        v-show="el.option_type == 3"
                      >
                        <div class="option_left">
                          <button
                            type="button"
                            :class="[
                              item.answer.includes(el.select) ? 'yes' : '',
                              item.wrong_option == el.select ? 'wrong' : '',
                            ]"
                          >
                            {{ el.select }}
                          </button>
                          <img :src="el.option_content" alt="" />
                        </div>
                        <a
                          :href="el.option_content"
                          class="option_right"
                          @click="yulan(el)"
                          target="_blank"
                        >
                          <img src="../../../../assets/img/yulan.png" alt="" />
                          大图预览
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- 答案解析 -->
                  <div class="analysis_card_box" ref="analysis_card">
                    <div class="analysis_card_top">
                      <div>正确答案:&nbsp;{{ item.answer }}</div>
                      <div
                        class="your_answer"
                        v-if="item.answer == item.user_answer"
                      >
                        您的答案:&nbsp;{{ item.user_answer }}
                        <img
                          src="../../../../assets/img/dui_answer.png"
                          alt=""
                        />
                      </div>
                      <div
                        class="your_answer wrong_answer"
                        v-else-if="
                          item.answer != item.user_answer &&
                          item.user_answer != ''
                        "
                      >
                        您的答案:&nbsp;{{ item.user_answer }}
                        <img src="../../../../assets/img/wrong.png" alt="" />
                      </div>
                      <div class="your_answer wrong_answer" v-else>
                        您的答案:&nbsp;未作答
                      </div>
                    </div>
                    <div class="analysis_card_down">
                      <i>答案解析:</i>
                      <div v-if="item.an_detail_metatype == 1">
                        {{ item.an_detail_content }}
                      </div>
                      <div
                        v-else-if="item.an_detail_metatype == 2"
                        v-html="item.an_detail_content"
                      ></div>
                      <img v-else :src="item.an_detail_content" alt="" />
                    </div>
                  </div>
                  <!-- 收藏 -->
                  <div class="coolect_box">
                    <div
                      class="coolect_left"
                      @click="isCollect(item)"
                      v-if="item.collect_status == false"
                    >
                      <img src="../../../../assets/img/collect.png" alt="" />
                      收藏试题
                    </div>
                    <div
                      class="coolect_left no_collect"
                      @click="noCollect(item)"
                      v-if="item.collect_status == true"
                    >
                      <img src="../../../../assets/img/no_collect.png" alt="" />
                      取消收藏
                    </div>
                    <div class="collect_line"></div>
                    <div class="coolect_right" @click="errorCorrection(item)">
                      <img src="../../../../assets/img/jiucuo.png" alt="" />
                      试题纠错
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 材料分析题 -->
            <div class="topic_box" v-if="stuff === 5">
              <div class="rule_box" id="stuff">
                材料分析题
                <!-- &nbsp;&nbsp;&nbsp;（共10小题，每题2分，每题多备选项中有2个或2个以上符合题意，至少有一个错项。错选，本题不得分；少选，所选的每个选项得0.5分) -->
              </div>
              <div
                v-for="(item, index) in paper_arr.filter(
                  (res) => res.qtype == 5
                )"
                :key="index + 'd'"
                class="topic_el"
              >
                <div :id="item.num"></div>
                <!-- 题目 -->
                <div class="topic_title_box">
                  <!-- 纯文字展示 -->
                  <div class="topic" v-show="item.case_metatype == 1">
                    <em>{{ item.num }}.</em><i>[材料分析题]</i
                    >{{ item.case_content }}
                  </div>
                  <!-- 富文本展示 -->
                  <div class="topic" v-show="item.case_metatype == 2">
                    <em>{{ item.num }}.</em><i>[材料分析题]</i>
                    <p v-html="item.case_content"></p>
                  </div>
                  <!-- 图片展示 -->
                  <div class="topic topic_img" v-show="item.case_metatype == 3">
                    <div>
                      <em>{{ item.num }}.</em><i>[材料分析题]</i>
                    </div>
                    <img :src="item.case_content" alt="" />
                  </div>
                </div>
                <div class="simple_box">
                  <em>简答题</em>（主观题无固定答案,不予评分）
                </div>
                <!-- 问题 -->
                <div class="problem_box">
                  <!-- <div :v-text="item.problem_content"></div> -->
                  <div v-show="item.problem == 1">
                    {{ item.problem_content }}
                  </div>
                  <div
                    v-show="item.problem == 2"
                    v-html="item.problem_content"
                  ></div>
                  <img
                    v-show="item.problem == 3"
                    :src="item.problem_content"
                    alt=""
                  />
                </div>
                <!-- 答案解析 -->
                <div class="analysis_card_box" ref="analysis_card">
                  <div class="analysis_card_down">
                    <i>答案解析:</i>
                    <div v-if="item.an_detail_metatype == 1">
                      {{ item.an_detail_content }}
                    </div>
                    <div
                      v-else-if="item.an_detail_metatype == 2"
                      v-html="item.an_detail_content"
                    ></div>
                    <img v-else :src="item.an_detail_content" alt="" />
                  </div>
                </div>

                <!-- 收藏 -->
                <div class="coolect_box">
                  <div
                    class="coolect_left"
                    @click="isCollect(item)"
                    v-if="item.collect_status == false"
                  >
                    <img src="../../../../assets/img/collect.png" alt="" />
                    收藏试题
                  </div>
                  <div
                    class="coolect_left no_collect"
                    @click="noCollect(item)"
                    v-if="item.collect_status == true"
                  >
                    <img src="../../../../assets/img/no_collect.png" alt="" />
                    取消收藏
                  </div>
                  <div class="collect_line"></div>
                  <div class="coolect_right" @click="errorCorrection(item)">
                    <img src="../../../../assets/img/jiucuo.png" alt="" />
                    试题纠错
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div id="white"></div> -->
          <!-- 右边答题卡 -->
          <div class="right_answer_sheet" id="card">
            <div class="card_content">
              <!-- 用时 -->
              <div class="card_top">
                <div class="card_top_left">
                  <div></div>
                  答题卡
                </div>
                <div class="card_top_right">
                  <img src="../../../../assets/img/yongshi.png" alt="" />
                  <p>用时</p>
                  <p>{{ time_length }}</p>
                </div>
              </div>
              <!-- 题号 -->
              <div class="num_box">
                <!-- 单选 -->
                <div class="num_el_box" v-show="radio == 1">
                  <div class="card_rules_box" @click="goTopic('radio')">
                    单项选择题（共{{ radio_total }}题）
                  </div>
                  <ul>
                    <li
                      v-for="(item, index) in paper_arr.filter(
                        (res) => res.qtype == 1
                      )"
                      :key="index + 'num1'"
                      @click="goTopic(item.num)"
                      :class="[
                        item.answer == item.user_answer ? 'green' : '',
                        item.answer != item.user_answer &&
                        item.user_answer != ''
                          ? 'red'
                          : '',
                      ]"
                    >
                      <!-- @click="goTopic(item.num)" -->
                      {{ item.num }}
                    </li>
                  </ul>
                </div>
                <!-- 配伍 -->
                <div class="num_el_box" v-show="peiwu == 2">
                  <div class="card_rules_box" @click="goTopic('peiwu')">
                    配伍题（共{{ peiwu_total }}题）
                  </div>
                  <div class="peiwu_nums_box">
                    <div
                      v-for="(item, index) in paper_arr.filter(
                        (res) => res.qtype == 2
                      )"
                      :key="index + 'num1'"
                      class="peiwu"
                    >
                      <div
                        v-for="(el, index) in item.qu_list"
                        :key="index + 'qu_list'"
                        @click="goAtherTopic(item, el, index)"
                        :class="[
                          el.answer == el.user_answer ? 'green' : '',
                          el.answer != el.user_answer && el.user_answer != ''
                            ? 'red'
                            : '',
                        ]"
                      >
                        {{ el.num }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 材料分析选择题 -->
                <div class="num_el_box" v-show="stuff_case == 3">
                  <div class="card_rules_box" @click="goTopic('stuff_case')">
                    材料分析选择题（共{{ stuff_case_total }}题）
                  </div>
                  <div class="peiwu_nums_box">
                    <div
                      v-for="(item, index) in paper_arr.filter(
                        (res) => res.qtype == 3
                      )"
                      :key="index + 'num1'"
                      class="peiwu"
                    >
                      <div
                        v-for="(el, index) in item.qu_list"
                        :key="index + 'qu_list'"
                        @click="goAtherTopic(item, el, index)"
                      >
                        {{ el.num }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 多选 -->
                <div class="num_el_box" v-show="checkbox == 4">
                  <div class="card_rules_box" @click="goTopic('checkbox')">
                    多项选择题（共{{ checkbox_total }}题）
                  </div>
                  <ul>
                    <li
                      v-for="(item, index) in paper_arr.filter(
                        (res) => res.qtype == 4
                      )"
                      :key="index + 'num1'"
                      @click="goTopic(item.num)"
                      :class="[
                        item.answer == item.user_answer ? 'green' : '',
                        item.answer != item.user_answer &&
                        item.user_answer != ''
                          ? 'red'
                          : '',
                      ]"
                    >
                      {{ item.num }}
                    </li>
                  </ul>
                </div>
                <!-- 材料分析题-->
                <div class="num_el_box" v-show="stuff == 5">
                  <div class="card_rules_box" @click="goTopic('stuff')">
                    材料分析题（不予评分）
                  </div>
                  <ul>
                    <li
                      v-for="(item, index) in paper_arr.filter(
                        (res) => res.qtype == 5
                      )"
                      :key="index + 'num1'"
                      @click="goTopic(item.num)"
                    >
                      {{ item.num }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 保存/交卷 -->
              <div class="btn_box">
                <ul>
                  <li>错误<span></span></li>
                  <li>正确<span></span></li>
                  <li>未作<span></span></li>
                </ul>
                <button type="button" @click="goBack">返回成绩单</button>
                <button type="button" @click="again(paper_info)">
                  重新做题
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Foot></Foot> -->
    <!-- 试题纠错弹窗 -->
    <div class="wrongmask_box" v-show="show_wrongmask == true">
      <div class="mask_box">
        <img
          src="../../../../assets/img/close.png"
          alt=""
          @click="show_wrongmask = false"
        />
        <div class="wrong_content">
          <div class="wrong_title">试题纠错</div>
          <div class="wrong_text">
            非常感谢您向我们提出错误，我们将尽力为您提供最优质的服务
          </div>
          <div class="wrong_text2">错误描述</div>
          <div class="wrong_ipt_box">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              maxlength="300"
              v-model="suggest"
            ></textarea>
            <p>{{ suggest.length }}/300</p>
          </div>
          <div class="submit_box">
            <button type="button" @click="show_wrongmask = false">取消</button>
            <button type="button" @click="submitError">提交纠错</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      paper_info: {}, //试卷成绩信息
      title: "", // 试卷标题
      paper_arr: [], // 试卷
      text: '<p>某施工单位采用下图所示的组织结构模式，则关于该组织结构的说法，正确的有( )。东方航空圣诞节风口浪尖螺丝钉解放路口监控圣诞节放假了可是拉克丝是开发架构就感觉哦数据管控perk公平可供[耳廓软骨看rg;jfkljsdl分几个阶段fog就开发速度快公开的反馈看来理发店过来的客观的</p><p><img src="https://liyouedu.cn/uploads/prod/20210530/d6b4089e89df1f6480bd3274bb54aec7.jpg" data-filename="filename" style="width: 466px;"><br></p>',
      radio: "", // 单选
      radio_total: "", // 单选总数
      peiwu: "", // 配伍题
      peiwu_total: "", // 配伍题总数
      stuff_case: "", // 材料分析选择
      stuff_case_total: "", // 材料分析选择总数
      checkbox: "", // 多选
      checkbox_total: "", // 多选总数
      stuff: "", // 材料分析
      stuff_total: "", // 材料分析总数
      user_answer: [], // 存储用户答案
      // choose: "", // 选择的答案
      choose_id: "", // 当前选项的id
      arrList: [], //
      checkboxArr: [], // 多选答案数组
      id_num: "",
      user_answer_list: [], // 接口返回用户答案数组
      user_answer:[],//用户答案
      time_length: "", //用时
      collect: false, // 是否收藏
      collectArr: [],
      loading: false, // 加载
      show_wrongmask: false, // 试题纠错弹窗
      suggest: "", // 纠错描述
      total_num: [], //各题型总数
      // qu_list: [], // 配伍题/ 案例选择 小题
      // str:"D,E"
    };
  },
  computed: {
    ad() {
      let { page, page_size } = this;
      return {
        page,
        page_size,
      };
    },
  },
  watch: {
    paper_arr: {
      deep: true,
      handler() {
        // // console.log("打印数组", this.paper_arr);
      },
    },
    ad(val) {
      // immediate: true,
      // handler() {
      // console.log("监听val====", val);
      // },
    },
  },
  beforeCreate() {},
  mounted() {
    // let card = document.getElementById("card");
    // let height = card.getBoundingClientRect().bottom;
    // card.style.maxHeight = height + "px";
  },

  filters: {},
  created() {
    // // console.log("时间===", this.toHHmmss(9000));
    this.loading = true;
    this.getDetail();
    // // console.log(this.$route.query.url);
    // 请求试卷缓存文件
    this.$axios({
      method: "get",
      url: this.$route.params.url,
      params: {
        paper_type: this.$route.paper_type,
      },
    }).then((res) => {
      if (parseInt(res.data.code) == 1) {
        this.loading = false;
        this.collectArr = res.data.data.list;
        this.title = res.data.data.title; // 试卷标题
        // // console.log("11111", res); //请求成功返回的数据

        let arr = res.data.data.list;
        // console.log("数组====", arr);
        // 处理后台返回的数据格式
        function generateList(arr) {
          // const optionsKey = ["A", "B", "C", "D", "E", "F"];
          let index = 1;
          return arr.map((item) => {
            const option = {};
            // optionsKey.forEach((key) => (option[key] = item[key]));
            // // console.log("option",option);
            const optionList = [
              {
                id: item.id,
                select: "A",
                option_content: item.an_item_a_content, // 选项内容
                option_type: item.an_item_a_metatype, // 选项类型
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
              {
                id: item.id,
                select: "B",
                option_content: item.an_item_b_content,
                option_type: item.an_item_b_metatype,
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
              {
                id: item.id,
                select: "C",
                option_content: item.an_item_c_content,
                option_type: item.an_item_c_metatype,
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
              {
                id: item.id,
                select: "D",
                option_content: item.an_item_d_content,
                option_type: item.an_item_d_metatype,
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
              {
                id: item.id,
                select: "E",
                option_content: item.an_item_e_content,
                option_type: item.an_item_e_metatype,
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
              {
                id: item.id,
                select: "F",
                option_content: item.an_item_f_content,
                option_type: item.an_item_f_metatype,
                choose: "", // 用户选择的答案
                // answer:item.answer, //正确答案
              },
            ];
            // this.$forceUpdate()
            // // console.log(optionList);
            if (item.qu_list && item.qtype != 3) {
              // // console.log("list", item.qu_list);
              return {
                // arr: item.qu_list[0],
                id: item.id,
                // name: item.name,
                qtype: item.qtype, // 单选/多选/配伍/材料解析/案例选择
                qu_content: item.qu_content, // 题目
                qu_metatype: item.qu_metatype, // 题目类型
                an_detail_content: item.an_detail_content, // 解析
                an_detail_metatype: item.an_detail_metatype, // 解析类型
                answer: item.answer, // 正确答案
                user_answer: item.user_answer, // 用户答案
                two_chaptercate_id: item.two_chaptercate_id, // 二级id
                chapter_category_id: item.chapter_category_id, //三级id
                is_collect: item.is_collect, // 收藏状态
                option_num: item.option_num,
                from_source: item.from_source,
                score: item.score,
                option: optionList,
                card_index: 0, // 小题下标
                collect_status: false, // 收藏状态
                // option: optionsKey,
                qu_list: item.qu_list.map((qu) => ({
                  id: qu.id,
                  // answer: qu.answer,
                  num: index++, // 题号
                  qu_content: qu.qu_content, // 题目
                  qu_metatype: qu.qu_metatype, // 题目类型
                  // an_detail_content: item.an_detail_content, // 解析
                  // an_detail_metatype: item.an_detail_metatype, // 解析类型
                  answer: qu.answer, // 正确答案
                  user_answer: qu.user_answer, // 用户答案
                  two_chaptercate_id: item.two_chaptercate_id, // 二级id
                  chapter_category_id: item.chapter_category_id, //三级id
                  qu_score: qu.qu_score,
                  // option: optionsKey,
                  option: optionList,
                  // option:
                })),
              };
            } else if (item.qtype == 3) {
              return {
                // arr: item.qu_list[0],
                id: item.id,
                // name: item.name,
                qtype: item.qtype, // 单选/多选/配伍/材料解析/案例选择
                // qu_content: item.qu_content, // 题目
                // qu_metatype: item.qu_metatype, // 题目类型
                // an_detail_content: item.an_detail_content, // 解析
                // an_detail_metatype: item.an_detail_metatype, // 解析类型
                case_content: item.case_content, // 题干
                case_metatype: item.case_metatype, // 题干类型
                // answer: item.answer, // 正确答案
                // user_answer: item.user_answer, // 用户答案
                two_chaptercate_id: item.two_chaptercate_id, // 二级id
                chapter_category_id: item.chapter_category_id, //三级id
                is_collect: item.is_collect, // 收藏状态
                // option_num: item.option_num,
                qu_num: item.qu_num,
                from_source: item.from_source,
                score: item.score,
                // option: optionList,
                card_index: 0, // 小题下标
                collect_status: false, // 收藏状态
                // option: optionsKey,
                qu_list: item.qu_list.map((qu) => ({
                  id: qu.id,
                  // answer: qu.answer,
                  num: index++, // 题号
                  qu_content: qu.qu_content, // 题目
                  qu_metatype: qu.qu_metatype, // 题目类型
                  an_detail_content: qu.an_detail_content, // 解析
                  an_detail_metatype: qu.an_detail_metatype, // 解析类型
                  answer: qu.answer, // 正确答案
                  user_answer: qu.user_answer, // 用户答案
                  two_chaptercate_id: item.two_chaptercate_id, // 二级id
                  chapter_category_id: item.chapter_category_id, //三级id
                  qu_score: qu.qu_score,
                  option_num: 5,
                  option_type: qu.option_type, // 用于安全类目 判断是单选多选
                  // option: optionsKey,
                  option: [
                    {
                      id: qu.id,
                      select: "A",
                      option_content: qu.an_item_a_content, // 选项内容
                      option_type: qu.an_item_a_metatype, // 选项类型
                      choose: "", // 用户选择的答案
                      // answer:item.answer, //正确答案
                    },
                    {
                      id: qu.id,
                      select: "B",
                      option_content: qu.an_item_b_content,
                      option_type: qu.an_item_b_metatype,
                      choose: "", // 用户选择的答案
                      qu, // answer:item.answer, //正确答案
                    },
                    {
                      id: qu.id,
                      select: "C",
                      option_content: qu.an_item_c_content,
                      option_type: qu.an_item_c_metatype,
                      choose: "", // 用户选择的答案
                      // answer:item.answer, //正确答案
                    },
                    {
                      id: qu.id,
                      select: "D",
                      option_content: qu.an_item_d_content,
                      option_type: qu.an_item_d_metatype,
                      choose: "", // 用户选择的答案
                      // answer:item.answer, //正确答案
                    },
                    {
                      id: qu.id,
                      select: "E",
                      option_content: qu.an_item_e_content,
                      option_type: qu.an_item_e_metatype,
                      choose: "", // 用户选择的答案
                      // answer:item.answer, //正确答案
                    },
                    {
                      id: qu.id,
                      select: "F",
                      option_content: qu.an_item_f_content,
                      option_type: qu.an_item_f_metatype,
                      choose: "", // 用户选择的答案
                      // answer:item.answer, //正确答案
                    },
                  ],
                  // option:
                })),
              };
            }
            return {
              id: item.id, // id
              num: index++, // 题号
              qtype: item.qtype, // 单选/多选/配伍/材料解析/案例选择
              qu_content: item.qu_content, // 题目
              qu_metatype: item.qu_metatype, // 题目类型
              an_detail_content: item.an_detail_content, // 解析
              an_detail_metatype: item.an_detail_metatype, // 解析类型
              case_content: item.case_content, // 材料
              case_metatype: item.case_metatype, // 材料类型
              problem: item.problem, // 材料问题类型
              problem_content: item.problem_content, // 材料问题
              answer: item.answer, // 正确答案
              user_answer: item.user_answer, // 用户答案
              two_chaptercate_id: item.two_chaptercate_id, // 二级id
              chapter_category_id: item.chapter_category_id, //三级id
              is_collect: item.is_collect, // 收藏状态
              option_num: item.option_num,
              from_source: item.from_source,
              score: item.score,
              collect_status: false, // 收藏状态
              // from_source:item.from_source,
              // name: item.name,
              // option: optionsKey,
              choose: "", // 用户选择的答案
              checkboxArr: [], // 多选题数组
              option: optionList,
            };
          });
        }
        this.paper_arr = generateList(arr);
        for (let v of this.paper_arr) {
          if (v.qtype == 1) {
            this.radio = 1;
          } else if (v.qtype == 2) {
            this.peiwu = 2;
          } else if (v.qtype == 3) {
            this.stuff_case = 3;
          } else if (v.qtype == 4) {
            this.checkbox = 4;
          } else {
            this.stuff = 5;
          }
          // 判断是否为错题解析
          // if (this.$route.params.type_id == 2) {
          //   // console.log(v.id);
          //   if (v.result == 0) {
          //     this.paper_arr.remove(v);
          //     // console.log(this.paper_arr);
          //   }
          // }
          if (JSON.parse(sessionStorage.getItem("userAnswer"))) {
            this.user_answer = JSON.parse(sessionStorage.getItem("userAnswer"));
          }
          for (let v in this.user_answer) {
            // // console.log("结果===",v);
            res.data.data.list.forEach((res) => {
              // // console.log("res",this.user_answer[v].ex_id);
              if (this.user_answer[v].ex_id == res.id) {
                res.result = this.user_answer[v].result;
                res.user_answer = this.user_answer[v].answer;
              }
              if (res.qu_list) {
                res.qu_list.forEach((ele) => {
                  // // console.log("ele", ele);
                  if (ele.id == this.user_answer[v].ex_qu_id) {
                    ele.result = this.user_answer[v].result;
                    ele.user_answer = this.user_answer[v].answer;
                  }
                });
              }
            });
          }
        }
        // console.log("处理格式1111111", this.paper_arr);
        this.wrongAnalysis();
      }
    });
    this.scroll();

    const arr = [
      { name: "harry", age: 14 },
      { name: "sam", age: 40 },
      { name: "gloria", age: 16 },
      { name: "riky", age: 33 },
    ];

    // const namesObj = Object.assign({}, arr);

    const convertArrtoObj = (arr, key) => {
      const initObj = {};
      return arr.reduce((obj, currVal) => {
        return {
          ...obj,
          [currVal[key]]: currVal,
        };
      }, initObj);
    };

    const newnamesObj = convertArrtoObj(arr, "age");

    // // console.log(newnamesObj);

    // // console.log("对象====", newnamesObj);

    let arr1 = this.$store.state.user_answer;
    // // console.log("arr2===", arr1);
  },

  methods: {
    // 返回成绩单
    goBack() {
      this.$router.go(-1);
    },
    // 重新做题
    again(item) {
      // sessionStorage.removeItem("user");
      // console.log(1111111111);
      this.$router.push({
        name: "Introduce",
        params: {
          nav_id: 9,
          chapter_id: this.$route.params.chapter_id,
          chapter_name: sessionStorage.getItem("chapterName"),
          paper_id: item.paper_id,
          paper_name: item.paper_title,
          router_name: "做题记录",
        },
      });
    },
    // 时间转换 时分秒
    toHHmmss(data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (data % (1000 * 60)) / 1000;
      time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      return time;
    },
    // 判断滚动距离,开启固定定位
    scroll() {
      // let distance =document.documentElement.scrollTop||document.body.scrollTop; //变量distance就是滚动条滚动时，到顶部的距离
      window.onscroll = function () {
        let title = document.getElementById("title");
        let card = document.getElementById("card");
        let card1 = document.getElementById("white");
        let scrollBox = document.getElementById("scroll_box");
        let distance = document
          .getElementById("card")
          .getBoundingClientRect().left;
        //获取距离页面顶部的距离
        let toTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (toTop > 111) {
          // title.style.position = "fixed";
          // title.style.top = 0;
          // title.style.marginTop = 0;

          card.style.position = "fixed";
          card.style.top = "0";
          card.style.left = distance + "px";
          // card.style.right = "19.38rem";
        } else {
          title.style.position = "";
          title.style.marginTop = "";
          card.style.position = "";
          card.style.top = "";
          card.style.right = "";
        }

        // // console.log("答题卡", card);
        //div距离顶部的距离
        // // console.log(document.getElementById("test").offsetTop);
      };
    },
    //   获取详情单
    getDetail() {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        id: this.$route.params.id, // 试卷id
      };
      api.record_detail(data).then((res) => {
        // // console.log(res.data.code);
        if (res.data.code == 1) {
          // console.log("试卷详情", res);
          this.paper_info = res.data.data;
          res.data.data.qtype_list.forEach((t) => {
            if (t.name == "单选题") {
              this.radio_total = t.total_num;
            } else if (t.name == "配伍题") {
              this.peiwu_total = t.total_num;
            } else if (t.name == "材料分析选择题(案例题)") {
              this.stuff_case_total = t.total_num;
            } else if (t.name == "多选题") {
              this.checkbox_total = t.total_num;
            }
          });
          // 调用时间转换方法
          this.time_length = this.toHHmmss(
            res.data.data.current_duration * 1000
          );
          // 将接口返回的用户答案赋值给对应题号的user_anser
          let answerList = res.data.data.user_answer;
          for (let v in answerList) {
            // // console.log(answerList[v]);
            for (let i of this.paper_arr) {
              if (v == "index_" + i.num) {
                i.user_answer = answerList[v].answer;
                i.result = answerList[v].result;

                let arr1 = i.answer.split(",");
                let arr2 = i.user_answer.split(",");
                let arr3 = [];
                arr2.forEach((item) => {
                  if (arr2.includes(item) && !arr1.includes(item)) {
                    arr3.push(item);
                  }
                });
                i.wrong_option = arr3.toString();
              }
              if (i.qu_list) {
                i.qu_list.map((ele) => {
                  if ((v = "index_" + ele.num))
                    ele.user_answer = answerList[v].answer;
                  ele.result = answerList[v].result;
                });
              }
              if (i.qtype == 3) {
                i.qu_list.map((ele) => {
                  let arr1 = ele.answer.split(",");
                  let arr2 = ele.user_answer.split(",");
                  let arr3 = [];
                  arr2.forEach((item) => {
                    if (arr2.includes(item) && !arr1.includes(item)) {
                      arr3.push(item);
                    }
                  });
                  ele.wrong_option = arr3.toString();
                });
              }
            }
          }

          let type_id = this.$route.params.type_id;
          let index = 1;
          if (type_id == 2) {
            let index = 1;
            this.paper_arr.map((ele) => {
              // console.log(ele.result);
            });
          }

          // // console.log(this.paper_arr);
          // this.user_answer_list = res.data.data.user_answer;
        }
        // // console.log(this.paper_arr);
      });
    },
    // 判断判断是否为错题解析进入
    wrongAnalysis() {
      let type_id = this.$route.params.type_id;
      let index = 1;
      if (type_id == 2) {
        this.paper_arr.map((ele) => {
          // console.log(ele.result);
        });
      }
      // console.log(type_id);
    },
    // 封装存储答案公共方法
    saveAnswer(item, el) {
      let obj = {
        num: item.num,
        select: el.select,
      };
      let storeArr = this.$store.state.user_answer;
      if (storeArr && storeArr != []) {
        let arrFind = storeArr.find((el) => item.num == el.num);
        if (arrFind) {
          arrFind.select = el.select;
        } else {
          storeArr.push(obj);
        }
      }
      // // console.log("存储答案====", storeArr);
      let arr = this.$store.commit("saveUserAnswer", storeArr);
      let arr1 = this.$store.state.user_answer;
      // console.log("本地存储数组===", arr1);
    },
    // 单选/多选 选择答案
    chooseAnswer(item, el) {
      // console.log("item===", item);
      // // console.log("nums===",nums);
      // console.log("el===", el);
      // 调用存储数据的方法
      // this.saveAnswer(item, el);
      // 查找到当前点击的题，改变choose的值，选项变色
      this.paper_arr.find((res) => {
        if (res.id == item.id && !res.qu_list) {
          // 单选 选项赋值
          if (item.qtype == 1) {
            res.option.find((ele) => {
              ele.choose = el.select;
            });
          } else if (item.qtype == 4) {
            // 找到当前题号,将多选选项推进数组
            let checkArr = this.paper_arr.find((pre) => pre.num == item.num);
            if (checkArr) {
              checkArr.checkboxArr.push(el.select);
              checkArr.checkboxArr.sort();
            }
            // 找到当前题号,将选项赋值给choose
            let findOption = res.option.find((ele) => ele.select == el.select);
            findOption.choose = el.select;
            // console.log(el);
          }
        }
      });
    },
    // 配伍/材料分析选择题选则答案
    atherChooseAnswer(item, el) {
      // 调用存储答案方法
      this.saveAnswer(item, el);
      // 判断是否存在qu_list,找到当前题号,给choose赋当前选项的值
      this.paper_arr.find((ele) => {
        if (ele.qu_list) {
          ele.qu_list.find((res) => {
            // // console.log("小题", res);
            if (res.num == item.num) {
              res.option.forEach((resOption) => {
                resOption.choose = el.select;
              });
            }
          });
        }
      });
    },
    // 配伍题/材料分析选择题 小题切换
    changeCard(res, index) {
      // console.log("当前下标", index);
      // 判断当前题id与选中id是否一致,更改下标 显示对应小题
      let findIndex = this.paper_arr.find((ele) => ele.id == res.id);
      if (findIndex) {
        findIndex.card_index = index;
      }
    },
    // 答题卡定位功能
    goTopic(num) {
      // console.log("题号=======", num);
      window.scrollTo({
        top: document.getElementById(num).offsetTop,
        behavior: "smooth",
      });
    },
    // 答题卡定位功能
    goAtherTopic(res, pre, index) {
      // 先找到本题, 赋值下标, 跳锚点定位到对应题目;
      let findIndex = this.paper_arr.find((ele) => ele.id == res.id);
      if (findIndex) {
        findIndex.card_index = index;
      }
      window.scrollTo({
        top: document.getElementById(pre.num).offsetTop,
        behavior: "smooth",
      });
      // // console.log(
      //   document.getElementById(pre.num).offsetTop - document.getElementById(pre.num).parentNode.offsetHeight
      // );
    },
    // 试题收藏
    isCollect(item) {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        ex_id: item.id, // 题目id
        qtype: item.qtype, // 题目类型
      };
      api.add_collect(data).then((res) => {
        let findId = this.paper_arr.find((ele) => ele.id == item.id);
        if (findId) {
          findId.collect_status = true;
        }
        if (parseInt(res.data.code) == 1) {
        }
        // console.log("收藏===", res);
      });
    },
    // 取消收藏
    noCollect(item) {
      // console.log("取消===", item);
      let data = {
        chapter_category: this.$route.params.chapter_id,
        ex_id: item.id, // 题目id
        qtype: item.qtype, // 题目类型
      };
      api.del_collect(data).then((res) => {
        let findId = this.paper_arr.find((ele) => ele.id == item.id);
        if (findId) {
          findId.collect_status = false;
        }
        // console.log("取消收藏===", res);
      });
    },
    // 试题纠错弹窗
    errorCorrection(item) {
      this.show_wrongmask = true; // 显示纠错弹窗
      this.topic_id = item.id;
      this.topic_type = item.qtype;
      // console.log("item=====", item);
    },
    // 提交纠错
    submitError() {
      if (this.suggest.length == 0) {
        this.$message.error({
          message: "描述不能为空!",
          duration: 2000,
        });
        return false;
      } else {
        let data = {
          chapter_category: this.$route.params.chapter_id, // 章节id
          ex_id: this.topic_id, // 题目id
          qtype: this.topic_type, // 题目类型
          suggest: this.suggest, // 纠错描述
        };
        api.correction_add(this.qs.stringify(data)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: res.data.msg,
              duration: 2000,
            });
            this.show_wrongmask = false; // 关闭纠错弹窗
          }
          // console.log("纠错======", res);
        });
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    position: relative;
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    overflow: hidden;
    .loading_box {
      width: 1200px;
      height: 872px;
      // height: 100vh;
      background: #fff;
      margin: 10px auto 0;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      align-items: center;
      img {
        width: 44px;
        height: 40px;
        margin: 227px 0 18px;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .content {
      position: relative;

      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 标题
      .title_box {
        // position: fixed;
        // top: 0;
        width: 1200px;
        height: 76px;
        background: #fff;
        margin: 16px 0 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 76px;
        text-align: center;
        z-index: 99;
      }
      #scroll_box {
        width: 100%;
        height: 195px;
        display: none;
        // background: pink;
      }
      // 试题
      .test_questions_box {
        // transform: scale(1, 1);
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: auto; /*关键点*/
        // 左边试题
        .left_topic_box {
          width: 880px;
          .topic_box {
            // 规则
            .rule_box {
              width: 839px;
              padding: 20px 20px;
              background: #ebf2ff;
              border-radius: 1px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e7eff;
            }
            .topic_el {
              width: 839px;
              height: 100%;
              background: #fff;
              margin: 10px 0;
              padding: 30px 20px 20px 20px;
              // 题目
              .topic_title_box {
                display: flex;
                font-size: 16px;
                font-family: PingFang SC;
                // font-weight: 400;
                color: #363636;
                margin-bottom: 25px;
                .topic {
                  em {
                    font-style: normal;
                    font-weight: 600;
                  }
                  i {
                    font-style: normal;
                    margin: 0 5px;
                    font-weight: 600;
                  }
                  img {
                    width: 650px;
                    height: 300px;
                  }
                }
              }
              // 答案解析
              .analysis_card_box {
                width: 808px;
                height: 100%;
                background: #f5f5f5;
                margin: 24px auto 20px;
                padding: 0 16px;
                .analysis_card_top {
                  // width: 100%;
                  height: 60px;
                  border-bottom: 1px dashed #dddddd;
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  div:nth-child(1) {
                    min-width: 92px;
                    color: #72b852;
                    margin-right: 57px;
                  }
                  .your_answer {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #72b852;
                    img {
                      width: 17px;
                      height: 12px;
                      margin-left: 20px;
                    }
                  }
                  .wrong_answer {
                    font-weight: 500;
                    color: #ee3d2b;
                    img {
                      width: 14px;
                      height: 14px;
                    }
                  }
                }
                .analysis_card_down {
                  display: flex;
                  // justify-content: space-between;
                  padding: 18px 0;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  i {
                    font-style: normal;
                    min-width: 70px;
                    margin-right: 10px;
                  }
                  img {
                    max-width: 729px;
                  }
                }
              }
              // 选项
              .option_box {
                .option_el {
                  margin-bottom: 6px;
                  .option {
                    display: flex;
                    align-items: center;
                    padding: 5px 0;
                    // cursor: pointer;
                    button {
                      min-width: 26px;
                      height: 26px;
                      border: 1px solid #989898;
                      background: #fff;
                      border-radius: 50%;
                      text-align: center;
                      font-style: normal;
                      margin: 0 14px 0 6px;
                    }
                    .yes {
                      background: #72b852;
                      border: none;
                      color: #fff;
                    }
                    .wrong {
                      background: #ee3d2b;
                      border: none;
                      color: #fff;
                    }
                    .blue {
                      width: 26px;
                      height: 26px;
                      background: #3e7eff;
                      border-radius: 50%;
                      color: #ffffff;
                      border: none;
                    }
                  }
                  // 富文本
                  .option_rich_text {
                    display: flex;
                    align-items: flex-start;
                  }
                  // 选项hover时
                  // .hover:hover {
                  //   padding: 5px 0;
                  //   background: #f7f9ff;
                  //   border-radius: 4px;
                  // }
                  // 纯图片
                  .option_img {
                    position: relative;
                    .option_left {
                      width: 686px;
                      // height: 300px;
                      display: flex;
                      align-items: flex-start;
                      overflow: hidden;
                      padding: 10px 0;
                      overflow: hidden;
                      // cursor: pointer;
                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }
                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      min-width: 85px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;
                      img {
                        width: 16px;
                        height: 16px;
                      }
                    }
                    // 选项hover时
                    // .option_left:hover {
                    //   // padding: 5px 0;
                    //   background: #f7f9ff;
                    //   border-radius: 4px;
                    // }
                  }
                }
              }
              // 材料分析题
              .simple_box {
                color: #666;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;

                em {
                  font-style: normal;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 600;
                  color: #222222;
                }
              }
              .problem_box {
                margin: 36px 0 15px;
                div {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #222222;
                  white-space: pre-wrap;
                }
                img {
                  max-width: 829px;
                }
              }

              // 收藏
              .coolect_box {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .coolect_left,
                .coolect_right {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  img {
                    width: 15px;
                    height: 14px;
                    margin-right: 4px;
                  }
                }
                .no_collect {
                  color: #3e7eff;
                }
                .collect_line {
                  width: 1px;
                  height: 17px;
                  background: #e5e5e5;
                  border-radius: 1px;
                  margin: 0 20px;
                }
              }
            }
          }
          // 配伍题
          .peiwu_topic_box {
            .topic_el {
              width: 840px;
              // height: 50px;
              // background: pink;
              padding: 30px 20px 20px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #363636;
              // 题目
              .peiwu_title_box {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #363636;
                .title_el {
                  display: flex;
                  margin-top: 16px;
                  i {
                    // display: block;
                    // min-width: 16px;
                    font-style: normal;
                  }
                  // 纯图片
                  .option_img {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .option_left {
                      width: 680px;
                      padding: 10px 0 10px 6px;
                      display: flex;
                      align-items: flex-start;
                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }
                    // .option_left:hover {
                    //   background: #f7f9ff;
                    //   border-radius: 4px;
                    // }
                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      display: flex;
                      align-items: center;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;
                      img {
                        width: 16px;
                        height: 16px;
                        margin-right: 9px;
                      }
                    }
                  }
                }
                // .title_el:hover {
                //   background: #f7f9ff;
                //   border-radius: 4px;

                // }
              }
              // 下方切换
              .toogle_box {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 20px;
                .toogle_top {
                  display: flex;
                  overflow: hidden;
                  li {
                    // div:nth-child(1) {
                    width: 60px;
                    height: 30px;
                    border-top: 3px solid #78a4ff;
                    border-bottom: 3px solid #78a4ff;
                    border-left: 3px solid #78a4ff;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #729cff;
                    line-height: 30px;
                    text-align: center;
                  }
                  .card_style {
                    background: #3e7eff;
                    color: #fff;
                  }
                  li:last-child {
                    border-right: 3px solid #78a4ff;
                  }
                }
                .toogle_down {
                  width: 840px;
                  // height: 280px;
                  .toogle_down_el {
                    padding: 32px 14px 32px 26px;
                    border: 2px solid #eeeeee;
                    // 标题
                    .small_topic_title {
                      display: flex;
                      margin-bottom: 30px;
                      em {
                        font-style: normal;
                      }
                    }
                    .small_topic_title:nth-child(3) {
                      display: flex;
                      flex-flow: column;
                      img {
                        max-width: 800px;
                      }
                    }
                    // 选项
                    .option_box {
                      .option_el {
                        margin-bottom: 6px;
                        .option {
                          display: flex;
                          align-items: center;
                          padding: 5px 0;
                          // cursor: pointer;
                          button {
                            min-width: 26px;
                            height: 26px;
                            border: 1px solid #989898;
                            background: #fff;
                            border-radius: 50%;
                            text-align: center;
                            font-style: normal;
                            margin: 0 14px 0 6px;
                          }
                          .yes {
                            background: #72b852;
                            border: none;
                            color: #fff;
                          }
                          .wrong {
                            background: #ee3d2b;
                            border: none;
                            color: #fff;
                          }
                          .blue {
                            width: 26px;
                            height: 26px;
                            background: #3e7eff;
                            border-radius: 50%;
                            color: #ffffff;
                            border: none;
                          }
                        }
                        // 富文本
                        .option_rich_text {
                          display: flex;
                          align-items: flex-start;
                        }
                        // 选项hover时
                        // .hover:hover {
                        //   padding: 5px 0;
                        //   background: #f7f9ff;
                        //   border-radius: 4px;
                        // }
                        // 纯图片
                        .option_img {
                          position: relative;
                          .option_left {
                            // width: 686px;
                            width: 840px;
                            // height: 300px;
                            display: flex;
                            align-items: flex-start;
                            overflow: hidden;
                            padding: 10px 0;
                            overflow: hidden;
                            // cursor: pointer;
                            img {
                              max-width: 650px;
                              max-height: 300px;
                            }
                          }
                          // 选项hover时
                          // .option_left:hover {
                          //   // padding: 5px 0;
                          //   background: #f7f9ff;
                          //   border-radius: 4px;
                          // }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #white {
          width: 879px;
          height: 100%;
          background: pink;
          z-index: -1;
          display: none;
        }
        /*滚动条整体部分,必须要设置*/
        ::-webkit-scrollbar {
          width: 6px;
          background: #eeeeee;
          border-radius: 3px;
        }
        /*滚动条的轨道*/
        ::-webkit-scrollbar-track {
          background-color: #eeeeee;
          border-radius: 3px;
        }
        /*滚动条的滑块按钮*/
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }
        // 右边答题卡
        .right_answer_sheet {
          width: 310px;
          height: 100%;
          background: #fff;
          overflow-y: auto;
          /*滚动条整体部分,必须要设置*/
          ::-webkit-scrollbar {
            width: 6px;
            background: #eeeeee;
            border-radius: 3px;
          }
          /*滚动条的轨道*/
          ::-webkit-scrollbar-track {
            background-color: #eeeeee;
            border-radius: 3px;
          }
          /*滚动条的滑块按钮*/
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #ccc;
          }
          .card_content {
            width: 100%;
            height: 100vh;
            margin: auto;
            // 用时
            .card_top {
              height: 50px;
              border-bottom: 1px solid #f5f5f5;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0 16px;
              .card_top_left {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                display: flex;
                align-items: center;
                div {
                  width: 3px;
                  height: 16px;
                  background: #d5362f;
                  border-radius: 2px;
                  margin-right: 9px;
                }
              }
              .card_top_right {
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 17px;
                }
                p:nth-child(2) {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  margin: 0 3px 0 7px;
                }
                p:nth-child(3) {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #d5362f;
                }
              }
            }
            // 题号
            .num_box {
              // height: 460px;
              // overflow-y: auto;
              margin-left: 16px;
              margin-top: 20px;
              .num_el_box {
                .card_rules_box {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  text-decoration: underline;
                  color: #3e7eff;
                  cursor: pointer;
                }
                ul {
                  // width: 278px;
                  margin: 17px auto 0;
                  display: flex;
                  flex-wrap: wrap;
                  overflow-x: hidden;
                  // justify-content: space-between;
                  li {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e5e7eb;
                    border-radius: 4px;
                    margin-right: 11px;
                    margin-bottom: 14px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;
                    text-align: center;
                    cursor: pointer;
                  }
                  .green {
                    color: #fff;
                    background: #72b852;
                  }
                  .red {
                    color: #fff;
                    background: #c23f2f;
                  }
                  // .peiwu {
                  //   // width: 100%;
                  //   // display: flex;
                  //   // flex-wrap: wrap;
                  //   // border: none;
                  //   // div {
                  //   //   width: 30px;
                  //   //   height: 30px;
                  //   //   border: 1px solid #e5e7eb;
                  //   //   border-radius: 4px;
                  //   //   margin-right: 11px;
                  //   //   margin-bottom: 14px;
                  //   //   font-size: 14px;
                  //   //   font-family: PingFang SC;
                  //   //   font-weight: 400;
                  //   //   color: #333333;
                  //   //   line-height: 30px;
                  //   //   text-align: center;
                  //   // }
                  // }
                }
                .peiwu_nums_box {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 17px;
                  .peiwu {
                    width: auto;
                    display: flex;
                    div {
                      width: 30px;
                      height: 30px;
                      border: 1px solid #e5e7eb;
                      border-radius: 4px;
                      margin-right: 11px;
                      margin-bottom: 14px;
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #333333;
                      line-height: 30px;
                      text-align: center;
                      cursor: pointer;
                    }
                    .green {
                      color: #fff;
                      background: #72b852;
                    }
                    .red {
                      color: #fff;
                      background: #c23f2f;
                    }
                  }
                }
              }
            }
            // 保存/交卷
            .btn_box {
              // width: 100%;
              height: 164px;
              border-top: 1px dashed #f5f5f5;
              margin: 0 16px;
              ul {
                width: 197px;
                display: flex;
                justify-content: space-between;
                margin: 18px auto;
                li {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  display: flex;
                  align-items: center;
                  span {
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: #c23f2f;
                    border-radius: 2px;
                    margin-left: 9px;
                  }
                }
                li:nth-child(2) {
                  span {
                    background: #72b852;
                  }
                }
                li:nth-child(3) {
                  span {
                    border: 1px solid #e5e7eb;
                    background: #fff;
                  }
                }
              }
              button:nth-child(2) {
                width: 278px;
                height: 46px;
                background: #eeeeee;
                border-radius: 6px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              button:nth-child(3) {
                width: 278px;
                height: 46px;
                background: #3e7eff;
                border-radius: 6px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #fff;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  // 试题纠错弹窗
  .wrongmask_box {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    width: 540px;
    height: 423px;
    margin: auto;
    z-index: 999;
    .mask_box {
      position: relative;
      width: 540px;
      height: 423px;
      border: 1px solid #dddddd;
      background: #fff;
      box-shadow: 0px 12px 24px 0px rgba(0, 13, 38, 0.1);
      border-radius: 8px;
      overflow: hidden;
      img {
        position: absolute;
        top: 11px;
        right: 11px;
      }
      .wrong_content {
        width: 460px;
        margin: 31px auto 0;
        .wrong_title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .wrong_text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          // margin-top: 14px;
          margin: 4px 0 21px;
        }
        .wrong_text2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
        }
        .wrong_ipt_box {
          position: relative;
          textarea {
            width: 426px;
            height: 178px;
            border: 1px solid #dddddd;
            border-radius: 2px;
            resize: none;
            padding: 17px 17px 25px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p {
            position: absolute;
            right: 11px;
            bottom: 11px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .submit_box {
          overflow: hidden;
          width: 200px;
          margin: 20px auto;
          button:nth-child(1) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3e7eff;
            border: 1px solid #3e7eff;
            background: #fff;
            margin-right: 20px;
          }
          button:nth-child(2) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
            border: 1px solid #3e7eff;
            background: #3e7eff;
          }
        }
      }
    }
  }
}
</style>
